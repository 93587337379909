.lessonprogresslesson__container {
  padding: 20px 10px;
  font-weight: bold;
  font-size: 16px;

  margin-bottom: 10px;
  border: 2px solid lightgrey;
  overflow: scroll;
}

.lessonprogresslesson__container > h3 {
  font-weight: bold;
}

.lessonSections__container {
  display: flex;
  flex-direction: column;
  /* border: 1px solid lightgrey; */
  /* margin-right: 40px; */
  min-width: 150px;
}

.usersectionsdata__container {
  display: flex;
  flex-direction: column;
}

.sectioncolumn__container {
  display: flex;
  flex-direction: row;
}

.userprogress__box {
  display: flex;
  font-weight: normal;
  padding: 1px 2px;
  flex-direction: row;

  font-size: 13px;
  text-align: center;
  justify-content: center;
}

.lessonSection__name {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  font-size: 12px;

  margin-bottom: 20px;
  text-align: center;
}

.lessonsection__completecount {
  font-size: 12px;
  opacity: 0.6;
}

.usersection__complete {
  background-color: lightgreen;
}

.lessonusers__container {
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  /* background-color: blue; */
}

.username__infocontainer {
  /* background-color: blue; */
  font-size: 14px;
  font-weight: normal;
  height: 21px;
  width: 190px;
}
