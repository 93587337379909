.modal-background {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10;
  display: flex;
}

.modal-background > div {
  margin-right: 100px;
  background-color: black;
}

.modal-close-button {
  position: fixed;
  right: 0px;
  margin: 10px;
  left: 100px;
  background-color: red;
  border: none;
  color: white;
  font-weight: bold;
  height: 50px;
  width: 100px;
}
