.quiz-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  border: 1px solid #ccc2c2;
  border-radius: 10px;
  min-width: 70vw;
  align-self: center;
  padding: 50px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 100px;
  margin-top: 100px;
  min-height: 400px;
}

.quiz-title {
  font-size: 2em;
  margin-bottom: 0;
  color: black;
}

.quiz-subtitle {
  font-size: 18px;
  color: lightslategrey;
  margin-bottom: 20px;
}

.quiz-start-button {
  padding: 10px 40px 10px 40px;
  border-radius: 6px;
  border: none;
  background-color: #ed5252;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}

@media only screen and (max-width: 900px) {
  .quiz-container {
    padding: 20px;
    justify-content: center;
    align-items: center;
  }

  .quiz-questions-container{
    max-width: 90vw;
  }

  .quiz-question {
    width: 100%;
  }
}
