.lesson-card-title {
  /* font-size: 1.5em; */
  margin-bottom: 0px;
  font-weight: bold;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-style: 1em;
}

.lesson-card-subtitle {
  margin-bottom: 0px;
  font-size: 1em;
  opacity: 0.5;
  font-weight: normal;
}

.lesson-card {
  /* padding: 20px; */
  font-weight: bold;
  max-width: 800px;
  margin-top: 30px;
  display: flex;
  box-shadow: 4px 3px 25px 0px rgb(223, 220, 220);
  margin: 20px;
  margin-left: 0px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  /* height: 340px; */
  padding: 40px;
  animation-name: appear;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  background-color: white;
  border-radius: 15px;
}

.lesson-card-title {
}

@keyframes appear {
  0% {
    margin-left: -30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

.lesson-card > h2 {
  /* font-weight: bold; */
}

.lesson-card > img {
  height: 230px;
  width: auto;
}

.start-lesson-link {
  padding: 10px 20px 10px 20px;
  background-color: #1c1d1f;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  /* width: 100%; */
  font-size: 16px;

  text-align: center;
  margin-top: 30px;
}

.start-lesson-link > a {
  color: white;
}

@media only screen and (max-width: 800px) {
  .lesson-card > img {
    display: none;
  }
}
