.loginbutton__container {
  width: 190px;
  height: 44px;
  margin-left: 23px;
  margin-right: 23px;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 30px;
  border: 1px solid lightgrey;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginbutton__container:hover {
  cursor: pointer;
}
