.finance-sheets-container {
  padding: 40px;
}

.finance-sheets {
  /* padding-left: 10px; */
  margin-top: 20px;
}

.finance__sheet {
  margin: 5px;
  margin-left: 0;
  padding-left: 0;
  padding: 10px;
  background-color: white;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 400px;
}
