.sidenav {
  position: fixed;
  height: 100%;
  width: 237px;
  top: 0;
  left: 0;
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 20px;
  border-right: 1px solid #ebebeb;
  box-shadow: 0px 2px 3px #aeadad;
  z-index: 200;
}

.navigationheaded__subtext {
  font-size: 10px;
  font-weight: lighter;
  opacity: 0.6;
}

.sidenav__link > p {
  margin-left: 14px;
}
.sidenav__link > img {
  margin-left: 16px;
}

.logout__button {
  background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoutbutton__container {
  width: 190px;
  height: 44px;
  margin-left: 23px;
  margin-right: 23px;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 30px;
  /* border: 1px solid lightgrey; */
  border-radius: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fdebeb;
  padding-left: 16px;
}

.logoutbutton__container > p {
  padding-left: 40px;
  font-weight: 600;
}

.logoutbutton__container:hover {
  cursor: pointer;
}

.sidenav a {
  padding: 3px 8px 6px 3px;
  text-decoration: none;
  font-size: 20px;
  color: black;
  display: block;
  margin-left: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 190px;
  height: 44px;
  margin-left: 23px;
  margin-right: 23px;
  margin-bottom: 7px;
}

.sidenav a:hover {
  background-color: #efefef;
  border-radius: 12px;
  box-shadow: inset 0 0 3px rgba(54, 54, 54, 0.1);
}

.sidenavlink__active {
  box-shadow: inset 0 0 3px rgba(54, 54, 54, 0.1);
  background-color: #efefef;
  border-radius: 12px;
}

.sidebar-survey {
  outline: none;
  background-color: #299cf3;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 50px;
}

.sidebar-survey {
  outline: none;
  background-color: #299cf3;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 0px;
}

.nav-text {
  font-size: 0.8rem;
  font-weight: normal;
  text-align: center;
  margin: 0;
}

i.fa {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 0.7em 0.8em;
  margin: 3px auto;
}
i.fa:hover {
  background-color: lightgrey;
}

.navicon-toggle {
  display: none;
}
.burger-toggler {
  display: none;
}

@media only screen and (max-width: 787px) {
  .sidenav {
    overflow: hidden;
    position: fixed;
    height: 50px;
    width: 100%;
    padding-top: 5px;
    z-index: 2000;
  }
  .active-sidenav {
    height: 100%;
    z-index: 10000;
    animation-name: dropdownNav;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }

  .navicon-toggle {
    display: none;
  }
  .burger-toggler {
    display: flex;
  }
}

@keyframes dropdownNav {
  0% {
    margin-top: -500px;
  }
  100% {
    margin-top: 0px;
  }
}
