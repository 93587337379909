.snowball-pro-form-container {
  display: flex;
  flex-direction: column;
  min-width: 500px;
  justify-content: center;
  align-items: flex-start;
  background-color: #3f3d56;
  padding: 40px;
  padding-top: 60px;
  margin-left: 100px;
  border-radius: 5px;
  animation-name: slide-right;
  animation-duration: 1s;
  opacity: 0;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  margin-top: 30px;
}

@keyframes slide-right {
  0% {
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .snowball-pro-form-container {
    min-width: auto;
    display: flex;
    width: 100vw;
  }

  .buy-button-container {
    flex-direction: column;
    min-height: 150px;
  }
}
