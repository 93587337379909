.shared-button {
  padding: 5px 10px 5px 10px;
  border: none;
  background-color: #3e3e3e;
  color: white;
  border-radius: 6px;
}

.shared-button:hover{
  opacity: 0.7;
}