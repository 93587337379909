.landing-top {
  width: 100%;
  padding: 20px 20px 0 0;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.landing-wrapper {
  background-color: white;
}

.snowball-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
}

.snowball-title {
  background-color: white;
  height: 450px;
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  box-shadow: 6px 7px 38px 4px rgb(223, 220, 220);
  color: #949090;
}

.landing-title {
  color: black;
  font-size: 3.5rem;
  margin: 0;
  text-align: center;
}

.landing-subtitle {
  font-size: 1.2rem;
  margin: 0;
  text-align: center;
}

.image-landing-mobile {
  display: none;
}

button:hover {
  cursor: pointer;
}

.snowball-title > h1 {
  font-size: 70px;
}

/* .button {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 80%;
} */

.login-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-position-x: 0rem;
  /* background-position-y: 20rem; */
  background-size: contain;
}

.landing-mid {
  width: 70%;
  /* height: 90%; */
  padding-top: 160px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: row;
  /* max-width: 100vw; */
  justify-content: space-evenly;
  align-items: center;
}

.landing-top-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding-top: 50px;
}

.landing-top-image > p {
  margin-bottom: 0px;
}

.bold-text {
  font-weight: 800;
  color: black;
}

.google-login {
  width: 230px;
  cursor: pointer;
  margin-top: 70px;
}

.landing-info {
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.image-info {
  padding-top: 70px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-grow: 0.5;
}

.info-text {
  font-size: 20px;
  max-width: 300px;
  text-align: center;
}

.info-image {
  text-align: center;
}

.info-image > h3 {
  padding-bottom: 10px;
}

.landing-quote {
  margin-top: 0;
  padding-top: 100px;
  margin-bottom: 1em;
  font-size: 25px;
  /* padding: 50px; */
  color: rgb(26, 25, 25);
  text-align: center;
  margin-right: 100px;
  margin-left: 100px;
}

.landing-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 5%;
  width: 100%;
  margin-bottom: 10px;
}

@media only screen and (max-width: 787px) {
  .snowball-title {
    background-color: white;
    /* height: 50%; */
    /* width: 80%; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* border-radius: 50%; */
    -webkit-box-shadow: none;
    /* box-shadow: 6px 7px 38px 4px rgb(223, 220, 220); */
    color: #949090;
  }

  .landing-top-image > p {
    margin-bottom: 0px;
    text-align: center;
  }

  .landing-top {
    display: none;
  }

  .landing-mid {
    padding-bottom: 100px;
  }

  .login-main {
    background: none;
  }

  /* .image-mobile {
    background: url(/assets/images/landing-back.png) center fixed;
    width: 100%;
    height: auto;
  } */

  .image-landing-mobile {
    display: block;
    width: 100%;
    margin-top: -130px;
  }
  .image-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
  }

  .info-image {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1338px) {
  .landing-mid {
    flex-direction: column;
  }

  .snowball-middle {
    padding-bottom: 50px;
  }
}
