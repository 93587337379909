.admin-row{
  background-color: white;
  font-size: 0.7em;
  font-size: 0.7em;
}
.admin-row:last-child{
  background-color: white !important;
}

tr:last-child{
  background-color: white !important;
}