.dashboard-levels {
  display: flex;
}
.progress-col > h5 {
  font-weight: bold;
}

.student-level {
  display: flex;
  justify-content: space-between;
  background: white;
  padding-right: 5px;
  padding: 3px 5px;
  font-size: 13px;
  border-radius: 5px;
  border: 0.5px solid lightgrey;
  margin-bottom: 5px;
  width: 200px;
}

.dashboard-level-container {
  margin: 10px;
  margin-left: 0;
  padding: 20px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.budgeting-game-done {
  background-color: green;
  color: white;
  padding: 2px 3px;
}

.dashboard-level-container > h2 {
  font-size: 24px;
  font-weight: bold;
}

.last__login {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}

.studenttable__container {
}
.editname__button {
  font-size: 10px;
  /* background-color: blue; */
  border: 1px solid lightgrey;
}

.userfirstlast__container {
  display: flex;
  justify-content: space-between;
}

.userfirstlast__container > div {
  font-size: 14px;
  min-width: 200px;
}

.studenttable > tr {
}

.offline__online__student {
  font-size: 14px;
}
