@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/* @font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url(./fonts/Ubuntu-Regular.woff) format("truetype");
} */

html body {
  /* height: 100%;
  width: 100%; */
  font-family: "Poppins";
  font-display: Arial;

  /* font-family: "Source Sans Pro", sans-serif; */
  /* font-family: "Source Sans Pro", sans-serif; */
}

section {
  padding-top: 60px;
  padding-bottom: 60px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* #root {
  height: 100%;
  width: 100%;
} */

.app {
  /* height: 100vh; */
  /* width: 100%;
  display: flex; */
  /* overflow: hidden; */
}

.content {
  margin-left: 237px;
  /* width: 100%; */
  height: fit-content;
  /* overflow: hidden; */
  min-height: 1000px;
  /* background-color: #f5f5f8; */
}

button:focus {
  outline: 0;
}

.btn {
  margin-top: 5px;
}

@media only screen and (max-width: 787px) {
  .content {
    /* display: none; */
    margin-left: 0px;
    margin-top: 60px;
    width: 100%;
  }
}

.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(
    135deg,
    rgb(49, 0, 62) 0%,
    rgb(195, 40, 110) 100%
  );
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}
