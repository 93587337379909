.classroom-container {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.classroom-stats {
  margin: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.classroom-stats > h5 {
  margin-bottom: 0;
  opacity: 0.6;
  font-size: 16px;
  /* margin-top: 40px; */
}

.classroom-stats > h3 {
  font-weight: bold;
  /* margin-bottom: 20px; */
}

.classroom-stats > p {
  opacity: 0.6;
}

.view-class-container {
  padding: 30px;
  /* height: 80vh; */
  overflow: scroll;
  padding-left: 10px;
}

.togglestudentviews__container {
  margin-bottom: 10px;
}

.exportdata__button {
  /* position: absolute; */
  /* right: 0px; */
  /* margin-right: 100px; */
  /* margin-left: 50px; */
  /* float: right; */
  margin-left: 20px;
}

.student-row {
  padding: 10px;
  margin: 5px;
  background-color: white;
  width: 400px;
}

.student-row-name {
  margin-bottom: 4px;
  font-weight: bold;
}

.student-data {
  padding: 3px 5px;
}

.student-row-lesson-data {
  /* width: 500px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.not-started-container {
  padding: 10px;
  background-color: white;
  height: 18vh;
  font-weight: bold;
  /* font-size: 1.5em; */
  margin-top: 20px;
}

.not-started-students {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.not-started {
  background-color: white;
  /* color: white; */
  border: 0.5px solid lightgrey;
  padding: 2px 4px;
  max-width: 100px;
  margin: 3px;
  font-size: 12px;
}

.classroom-progress-cols {
  display: flex;
  flex-direction: column;
  /* width: 90vw; */
  justify-content: flex-start;
}

.progress-col {
  margin: 20px;
  margin-left: 0px;
  width: 250px;
}

.progress-col > h5 {
  /* height: 50px; */

  padding-bottom: 0px;
  margin-bottom: 10px;
  font-style: 18px;
}

.progress-game-cols {
  display: flex;
  flex-direction: row;
}

.progress-lesson-col > h5 {
  margin: 10px;
  margin-left: 0px;
  max-width: 100px;
  font-weight: bold;
  font-size: 1em;
}

.progress-col {
  width: 100;
}

.classdata__refresh {
  
}
