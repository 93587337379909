.pro-title {
  color: white;
  font-weight: bold;
  padding-bottom: 30px;
}

.pro-red {
  color: #ff0202;
}

.detail {
  /* font-weight: bold; */
  color: white;
  font-size: 18px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.buy-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 30px;
}

.buy-button-pay {
  padding: 15px;
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  border: none;
  background-color: #ff0000;
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
  box-shadow: 0 0 4px 3px #2d2c2c;
}

.buy-button > button:hover {
  background-color: black;
  box-shadow: none;
}

.buy-button-pay:hover {
  background-color: #ff3838;
  transform: scale(1.1);
}

.buy-button-prices {
  display: flex;
  flex-direction: column;
}

.buy-button-prices > p {
  margin-bottom: 0px;
  font-size: 1.5em;
  color: white;
  font-weight: bold;
}

.buy-discount {
  text-decoration: line-through;
  font-size: 1.5em;
}
