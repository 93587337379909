.session__time {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
}

.session__time > div {
  font-weight: bold;
}

.navigationheader__container {
  padding-left: 23px;
  margin-bottom: 43px;
}

.navigationheader__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.navigationheader__text {
  margin-left: 20px;
  font-size: 16px;
  font-weight: 600;
}
