@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);

.choose {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: left;
  /* background-color: #f5f5f8; */
}

.sim-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin: 40px 0px 0px 60px; */
  padding: 30px;
  padding-left: 50px;
  min-height: 100vh;
  /* background-color: #f5f5f8; */
}

.inactive-bar-pro {
  height: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.inactive-bar-pro > h5 {
  border-radius: 15px;
  padding: 5px;
  background: red;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.inactive-bar-pro > h5:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.game-card {
  display: flex;
  flex-direction: row;
  -webkit-animation-name: slideLeft;
          animation-name: slideLeft;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes slideLeft {
  0% {
    margin-left: -50px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    margin-left: -50px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

.game-description {
  width: 50%;
  font-size: 18px;
}

.simulations {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  flex-direction: column;
}

.active-card,
.inactive-card {
  height: 280px;
  width: 410px;
  background-color: yellow;
  margin: 0px 70px 45px 0px;
  box-shadow: 4px 3px 25px 0px rgb(223, 220, 220);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.active-card:hover {
  text-decoration: none;
  cursor: pointer;
  box-shadow: 5px 7px 25px 0px rgb(223, 220, 220);
  display: flex;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: all 0.2s;
  margin-left: 20px;
}

.active-card:hover .game-description {
  display: inline;
}

.card-graphic {
  height: 84%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-img {
  max-width: 300px;
  max-height: 180px;
  width: auto;
  height: auto;
}

.active-bar,
.inactive-bar {
  height: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.active-bar {
  background-color: #213042;
}

.inactive-bar {
  background-color: #a5caf4;
}

.sim-header {
  width: 80%;
  margin-top: 30px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: left;
  color: #525252;
  /* margin-bottom: 30px; */
}

.sim-title {
  font-size: 2.4rem;
  margin-bottom: 0.1rem;
  font-weight: bold;
}

.sim-subtitle {
  color: #a5a2a2;
  font-size: 1.2rem;
  margin-bottom: 0px;
}

.card-title {
  color: #525252;
  text-decoration: none;
  margin: 0;
}

.card-message {
  color: white;
  margin: 0;
  font-size: 1.4rem;
}

.card-message > h5 {
  border-radius: 15px;
  padding: 5px;
  background: red;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 960px) {
  .game-card {
    flex-direction: column;
    padding-bottom: 20px;
  }

  .choose {
    display: inline;
  }

  .game-description {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    text-align: left;
    width: 80%;
  }

  .sim-menu {
    margin: 30px;
    margin-top: 0px;
    width: 100%;
    margin-left: 0px;
    padding: 30px;
    /* margin-left: 10px; */
    /* background-color: #f5f5f8; */
  }

  .sim-header {
    height: auto;
  }

  .ant-steps {
    display: flex;
    margin-left: -20px;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  .ant-steps-vertical {
    margin-left: -20px;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
  }

  .active-card,
  .inactive-card {
    width: auto;
    /* padding: 5px; */
    background-color: white;
    height: auto;
    margin-right: 0px;
  }

  .active-bar,
  .inactive-bar {
    height: 40px;
  }

  .card-title {
  }
}

.pro-title {
  color: white;
  font-weight: bold;
  padding-bottom: 30px;
}

.pro-red {
  color: #ff0202;
}

.detail {
  /* font-weight: bold; */
  color: white;
  font-size: 18px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.buy-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 30px;
}

.buy-button-pay {
  padding: 15px;
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  border: none;
  background-color: #ff0000;
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
  box-shadow: 0 0 4px 3px #2d2c2c;
}

.buy-button > button:hover {
  background-color: black;
  box-shadow: none;
}

.buy-button-pay:hover {
  background-color: #ff3838;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.buy-button-prices {
  display: flex;
  flex-direction: column;
}

.buy-button-prices > p {
  margin-bottom: 0px;
  font-size: 1.5em;
  color: white;
  font-weight: bold;
}

.buy-discount {
  text-decoration: line-through;
  font-size: 1.5em;
}

.snowball-pro-form-container {
  display: flex;
  flex-direction: column;
  min-width: 500px;
  justify-content: center;
  align-items: flex-start;
  background-color: #3f3d56;
  padding: 40px;
  padding-top: 60px;
  margin-left: 100px;
  border-radius: 5px;
  -webkit-animation-name: slide-right;
          animation-name: slide-right;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  opacity: 0;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  margin-top: 30px;
}

@-webkit-keyframes slide-right {
  0% {
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .snowball-pro-form-container {
    min-width: auto;
    display: flex;
    width: 100vw;
  }

  .buy-button-container {
    flex-direction: column;
    min-height: 150px;
  }
}

.modal-background {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10;
  display: flex;
}

.modal-background > div {
  margin-right: 100px;
  background-color: black;
}

.modal-close-button {
  position: fixed;
  right: 0px;
  margin: 10px;
  left: 100px;
  background-color: red;
  border: none;
  color: white;
  font-weight: bold;
  height: 50px;
  width: 100px;
}

.skeleton{
  background-color: white;
}
.lesson-card-title {
  /* font-size: 1.5em; */
  margin-bottom: 0px;
  font-weight: bold;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-style: 1em;
}

.lesson-card-subtitle {
  margin-bottom: 0px;
  font-size: 1em;
  opacity: 0.5;
  font-weight: normal;
}

.lesson-card {
  /* padding: 20px; */
  font-weight: bold;
  max-width: 800px;
  margin-top: 30px;
  display: flex;
  box-shadow: 4px 3px 25px 0px rgb(223, 220, 220);
  margin: 20px;
  margin-left: 0px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  /* height: 340px; */
  padding: 40px;
  -webkit-animation-name: appear;
          animation-name: appear;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  background-color: white;
  border-radius: 15px;
}

.lesson-card-title {
}

@-webkit-keyframes appear {
  0% {
    margin-left: -30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    margin-left: -30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

.lesson-card > h2 {
  /* font-weight: bold; */
}

.lesson-card > img {
  height: 230px;
  width: auto;
}

.start-lesson-link {
  padding: 10px 20px 10px 20px;
  background-color: #1c1d1f;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  /* width: 100%; */
  font-size: 16px;

  text-align: center;
  margin-top: 30px;
}

.start-lesson-link > a {
  color: white;
}

@media only screen and (max-width: 800px) {
  .lesson-card > img {
    display: none;
  }
}

.shared-input {
  margin: 5px;
  display: block;
}

.shared-button {
  padding: 5px 10px 5px 10px;
  border: none;
  background-color: #3e3e3e;
  color: white;
  border-radius: 6px;
}

.shared-button:hover{
  opacity: 0.7;
}
.footer-text {
  color: white;
}

.footer-dark {
  padding: 50px 0;
  color: #f0f9ff;
  background-color: #282d32;
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}

.footer-dark ul a:hover {
  opacity: 0.8;
}

.copyrights{
  text-align: center;
}

@media (max-width: 767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-dark .item.text {
  margin-bottom: 36px;
}

@media (max-width: 767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width: 991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}

.footer-dark .item.social > a {
  font-size: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
  margin: 0 8px;
  color: #fff;
  opacity: 0.75;
}

.footer-dark .item.social > a:hover {
  opacity: 0.9;
}


.footer-dark .copyright {
  text-align: center;
  padding-top: 24px;
  opacity: 0.3;
  font-size: 13px;
  margin-bottom: 0;
  color: white;
}

.landing-top {
  width: 100%;
  padding: 20px 20px 0 0;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.landing-wrapper {
  background-color: white;
}

.snowball-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
}

.snowball-title {
  background-color: white;
  height: 450px;
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  box-shadow: 6px 7px 38px 4px rgb(223, 220, 220);
  color: #949090;
}

.landing-title {
  color: black;
  font-size: 3.5rem;
  margin: 0;
  text-align: center;
}

.landing-subtitle {
  font-size: 1.2rem;
  margin: 0;
  text-align: center;
}

.image-landing-mobile {
  display: none;
}

button:hover {
  cursor: pointer;
}

.snowball-title > h1 {
  font-size: 70px;
}

/* .button {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 80%;
} */

.login-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-position-x: 0rem;
  /* background-position-y: 20rem; */
  background-size: contain;
}

.landing-mid {
  width: 70%;
  /* height: 90%; */
  padding-top: 160px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: row;
  /* max-width: 100vw; */
  justify-content: space-evenly;
  align-items: center;
}

.landing-top-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding-top: 50px;
}

.landing-top-image > p {
  margin-bottom: 0px;
}

.bold-text {
  font-weight: 800;
  color: black;
}

.google-login {
  width: 230px;
  cursor: pointer;
  margin-top: 70px;
}

.landing-info {
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.image-info {
  padding-top: 70px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-grow: 0.5;
}

.info-text {
  font-size: 20px;
  max-width: 300px;
  text-align: center;
}

.info-image {
  text-align: center;
}

.info-image > h3 {
  padding-bottom: 10px;
}

.landing-quote {
  margin-top: 0;
  padding-top: 100px;
  margin-bottom: 1em;
  font-size: 25px;
  /* padding: 50px; */
  color: rgb(26, 25, 25);
  text-align: center;
  margin-right: 100px;
  margin-left: 100px;
}

.landing-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 5%;
  width: 100%;
  margin-bottom: 10px;
}

@media only screen and (max-width: 787px) {
  .snowball-title {
    background-color: white;
    /* height: 50%; */
    /* width: 80%; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* border-radius: 50%; */
    -webkit-box-shadow: none;
    /* box-shadow: 6px 7px 38px 4px rgb(223, 220, 220); */
    color: #949090;
  }

  .landing-top-image > p {
    margin-bottom: 0px;
    text-align: center;
  }

  .landing-top {
    display: none;
  }

  .landing-mid {
    padding-bottom: 100px;
  }

  .login-main {
    background: none;
  }

  /* .image-mobile {
    background: url(/assets/images/landing-back.png) center fixed;
    width: 100%;
    height: auto;
  } */

  .image-landing-mobile {
    display: block;
    width: 100%;
    margin-top: -130px;
  }
  .image-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
  }

  .info-image {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1338px) {
  .landing-mid {
    flex-direction: column;
  }

  .snowball-middle {
    padding-bottom: 50px;
  }
}

/* Full landing Container */
.landing-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
}

.discount {
  background-color: #ed5252;
  color: white;
  text-align: center;
  padding: 20px;
  /* align-self: center; */
}

.discount > button {
  border: none;
  margin: 5px;
  color: black;
}

.newsletter__container{
  background-color: black;
  margin: 50px;
}

/* Landing First Thing you see */

.intro-landing {
  display: flex;
  flex: 1 1;
  background-color: white;
  padding: 150px;
  padding-bottom: 100px;
  flex-direction: row;
  justify-content: space-between;
}

.play-now {
  width: 250px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #3f3d55;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 20px;
  text-align: center;
  -webkit-animation-name: fadeInFromLeft;
          animation-name: fadeInFromLeft;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.brands {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 80vw;
  align-self: center;
}

.brands:first-child {
  width: 400px;
}

.brands-title {
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
}

.brand-image {
  /* width: auto; */
  margin-bottom: 30px;
  height: auto;

  max-height: 90px;
  /* max-width: 300px; */
  height: auto;
}

.intro-landing-text-container {
  display: flex;
  justify-content: center;
  color: #3e3e3e;
  font-weight: bold;
}

.intro-landing-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-weight: bold;
  max-width: 400px;
}

.intro-landing-text > h1 {
  font-size: 3em;
  opacity: 0;
  max-width: 400px;
  color: #3e3e3e;
  font-weight: bold;
  padding-bottom: 0px;
  margin-bottom: 0px;
  -webkit-animation-name: fadeInFromLeft;
          animation-name: fadeInFromLeft;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.intro-landing-text > p {
  font-size: 1.8em;
  padding-top: 20px;
  opacity: 0;
  -webkit-animation-name: fadeInFromLeft;
          animation-name: fadeInFromLeft;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.landing-action-button {
  background-color: #3f3d56;
  color: white;
  font-size: 1.4em;
  opacity: 0;
  -webkit-animation-name: fadeInFromLeft;
          animation-name: fadeInFromLeft;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.row-container-1-image {
  -webkit-animation-name: fadeInFromRight;
          animation-name: fadeInFromRight;
  opacity: 0;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

/* Landing Second Container */

.games-showoff-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 150px;
  padding-bottom: 100px;
}

.showoff-image {
  max-width: 600px;
  -webkit-animation-name: fadeInFromRight;
          animation-name: fadeInFromRight;
  opacity: 0;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

/* Third Container */
.showoff-sheets-container {
  padding: 120px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.showoff-sheets-text {
  margin-bottom: 50px;
  font-size: 1em;
}

.showoff-text {
  /* min-width: 400px; */
}

.showoff-sheets-text > h1 {
  font-size: 3em;
  opacity: 0;
  color: #3e3e3e;
  font-weight: bold;
  text-align: left;
  padding-bottom: 0px;
  margin-bottom: 0px;
  -webkit-animation-name: fadeInFromLeft;
          animation-name: fadeInFromLeft;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.showoff-sheets-text > p {
  font-size: 1.8em;
  padding-top: 20px;
  text-align: center;
  opacity: 0;
  -webkit-animation-name: fadeInFromLeft;
          animation-name: fadeInFromLeft;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

/* Fourth Container */
.features-container {
  display: flex;
  flex-direction: row;
  padding: 120px;
  padding-top: 80px;
  margin-bottom: 50px;
  justify-content: space-around;
}

.features-text-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  justify-content: center;
}

.features-text-container > h1 {
  /* font-weight: bold; */
  font-size: 32px;
  margin-bottom: 15px;
}

.features-list > p {
  font-size: 18px;
  margin-bottom: 10px;
  /* padding-left: 20px; */
  text-align: right;
  display: flex;
}

.features-list {
  margin-bottom: 20px;
}

.landing-button-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 100px;
  opacity: 0;
  -webkit-animation-name: fadeInFromLeft;
          animation-name: fadeInFromLeft;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.landing-button-bottom > button {
  width: 50%;
  align-self: center;
  margin-bottom: 100px;
  background-color: #3f3d56;
  color: white;
  font-size: 1.4em;
  box-shadow: none;
}

.landing-button-bottom > h1 {
  font-size: 4em;
}

.google-login {
  opacity: 0;
  -webkit-animation-name: fadeInFromLeft;
          animation-name: fadeInFromLeft;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.start-now-button {
  background-color: rgb(28, 29, 31);
  color: white;
}

.row-container-2-image {
  width: 70vw;
}

@-webkit-keyframes fadeInFromLeft {
  0% {
    margin-left: -30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@keyframes fadeInFromLeft {
  0% {
    margin-left: -30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeInFromRight {
  0% {
    margin-right: -30px;
    opacity: 0;
  }
  100% {
    margin-right: 0px;
    opacity: 1;
  }
}

@keyframes fadeInFromRight {
  0% {
    margin-right: -30px;
    opacity: 0;
  }
  100% {
    margin-right: 0px;
    opacity: 1;
  }
}

@media only screen and (max-width: 1350px) {
  /* Landing first thing you see mobile */

  .landing-container > section:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  .intro-landing {
    padding: 20px;
    flex-direction: column;
    /* margin-top: 120px; */
    /* margin-bottom: 120px; */
  }
  .intro-landing-image-container {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 300px;
    align-self: center;
  }

  .intro-landing-text-container {
    /* justify-content: flex-start;
    padding: 40px; */
    margin-top: 60px;
  }

  .showoff-sheets-text > h1 {
    font-size: 3em;
  }

  .showoff-sheets-text > p {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .features-text-container > h1 {
    font-size: 2em;
  }

  .features-text-container {
    text-align: center;
  }

  .features-list {
    margin-top: 15px;
    max-width: 600px;
    height: auto;
  }

  .row-container-features-image {
    display: none;
  }

  .features-list > p {
    font-size: 1.5em;
    opacity: 0.7;
  }

  .intro-landing-text > h1 {
    font-size: 3em;
  }

  .games-showoff-container {
    flex-direction: column;
    align-items: center;
  }
  .row-container-1-image {
    padding: 20px;
    width: 600px;
    max-width: 100vw;
    /* max-width: 30vw; */
  }

  /* Second container mobile */

  .landing-action-button {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 120px;
  }

  .games-showoff-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    padding: 20px;
    margin-bottom: 120px;
  }

  .showoff-image {
    /* margin-top: 0px; */
    width: 80vw;
    /* max-width: 100%; */
    /* max-width: 100vw; */
    /* height: auto; */
  }
  .showoff-text {
    margin-top: 50px;
    align-self: center;
    padding-bottom: 20px;
    padding: 20px;
  }

  /* Third Container mobile */
  .showoff-sheets-container {
    padding: 20px;
    /* padding-top: 100px; */
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
  }

  .showoff-sheets-text {
    align-items: flex-start;
    text-align: center;
  }

  .showoff-sheets-text > p {
    text-align: left;
  }

  .showoff-sheets-container > img {
    margin-right: 20px;
  }

  /* Fourth Container */
  .features-container {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  .builtwith-images {
    display: flex;
  }

  .builtwith-images > img {
    min-width: 150px;
    background-color: blue;
  }

  .features-text-container {
    align-items: center;
    align-items: flex-start;
  }
  .features-text-container > h1 {
    text-align: center;
  }

  .features-list-container {
    justify-content: center;
    align-items: center;
  }

  .features-list {
    align-items: flex-start;
    margin-bottom: 100px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }

  .landing-button-bottom {
    text-align: center;
  }

  .row-container-features-image {
    padding: 20px;
    height: auto;
    width: auto;
  }

  .start-now-button {
    background-color: rgb(28, 29, 31);
    color: white;
    width: 100%;
    margin-top: 0px;
    font-size: 1.4em;
    margin-bottom: 90px;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 813px) {
}

@media only screen and (max-width: 320px) {
  .showoff-sheets-container {
    width: 100%;
    font-size: 0.8em;
  }

  .ant-steps-item-container {
    margin-left: -75px;
  }

  .card-graphic {
    /* margin-left: -20px; */
    z-index: 10;
  }

  .features-text-container > h1 {
    font-size: 2em;
  }
}

.options-box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.quiz-title {
  font-weight: bold;
}

.quiz-question {
  font-weight: bold;
  font-size: 1.3em;
}

.quiz-answer {
  flex: 1 1;
  border: none;
  padding: 20px;
  margin: 3px;
  font-weight: bold;
}

.quiz-answer:hover {
  background-color: #3e3e3e;
  color: white;
}

.quiz-questions-container {
  max-width: 40vw;
  -webkit-animation-name: questions-appear;
          animation-name: questions-appear;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  min-height: 200px;
}

.results-need-snowball {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 50vw;
  min-height: 100px;
}

.results-button {
  margin-top: 30px;
  padding: 10px 40px 10px 40px;
  border-radius: 6px;
  border: none;
  background-color: #3e3e3e;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}

.results-need-snowball > h4 {
  text-align: center;
}
.results-need-snowball > p {
  margin-top: 10px;
  text-align: center;
  font-size: 1.2em;
}

.failed-span {
  background-color: #ed5252;
  color: white;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.success-span {
  background-color: #50ea50;
  color: white;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

@-webkit-keyframes questions-appear {
  0% {
    /* margin-bottom: -30px; */
    opacity: 0;
  }
  100% {
    /* margin-bottom: -30px; */
    opacity: 1;
  }
}

@keyframes questions-appear {
  0% {
    /* margin-bottom: -30px; */
    opacity: 0;
  }
  100% {
    /* margin-bottom: -30px; */
    opacity: 1;
  }
}

.quiz-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  border: 1px solid #ccc2c2;
  border-radius: 10px;
  min-width: 70vw;
  align-self: center;
  padding: 50px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 100px;
  margin-top: 100px;
  min-height: 400px;
}

.quiz-title {
  font-size: 2em;
  margin-bottom: 0;
  color: black;
}

.quiz-subtitle {
  font-size: 18px;
  color: lightslategrey;
  margin-bottom: 20px;
}

.quiz-start-button {
  padding: 10px 40px 10px 40px;
  border-radius: 6px;
  border: none;
  background-color: #ed5252;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}

@media only screen and (max-width: 900px) {
  .quiz-container {
    padding: 20px;
    justify-content: center;
    align-items: center;
  }

  .quiz-questions-container{
    max-width: 90vw;
  }

  .quiz-question {
    width: 100%;
  }
}

.policy {
  padding: 50px 200px 0px 50px;
}
.sidenav {
  position: fixed;
  height: 100%;
  width: 237px;
  top: 0;
  left: 0;
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 20px;
  border-right: 1px solid #ebebeb;
  box-shadow: 0px 2px 3px #aeadad;
  z-index: 200;
}

.navigationheaded__subtext {
  font-size: 10px;
  font-weight: lighter;
  opacity: 0.6;
}

.sidenav__link > p {
  margin-left: 14px;
}
.sidenav__link > img {
  margin-left: 16px;
}

.logout__button {
  background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoutbutton__container {
  width: 190px;
  height: 44px;
  margin-left: 23px;
  margin-right: 23px;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 30px;
  /* border: 1px solid lightgrey; */
  border-radius: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fdebeb;
  padding-left: 16px;
}

.logoutbutton__container > p {
  padding-left: 40px;
  font-weight: 600;
}

.logoutbutton__container:hover {
  cursor: pointer;
}

.sidenav a {
  padding: 3px 8px 6px 3px;
  text-decoration: none;
  font-size: 20px;
  color: black;
  display: block;
  margin-left: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 190px;
  height: 44px;
  margin-left: 23px;
  margin-right: 23px;
  margin-bottom: 7px;
}

.sidenav a:hover {
  background-color: #efefef;
  border-radius: 12px;
  box-shadow: inset 0 0 3px rgba(54, 54, 54, 0.1);
}

.sidenavlink__active {
  box-shadow: inset 0 0 3px rgba(54, 54, 54, 0.1);
  background-color: #efefef;
  border-radius: 12px;
}

.sidebar-survey {
  outline: none;
  background-color: #299cf3;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 50px;
}

.sidebar-survey {
  outline: none;
  background-color: #299cf3;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 0px;
}

.nav-text {
  font-size: 0.8rem;
  font-weight: normal;
  text-align: center;
  margin: 0;
}

i.fa {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 0.7em 0.8em;
  margin: 3px auto;
}
i.fa:hover {
  background-color: lightgrey;
}

.navicon-toggle {
  display: none;
}
.burger-toggler {
  display: none;
}

@media only screen and (max-width: 787px) {
  .sidenav {
    overflow: hidden;
    position: fixed;
    height: 50px;
    width: 100%;
    padding-top: 5px;
    z-index: 2000;
  }
  .active-sidenav {
    height: 100%;
    z-index: 10000;
    -webkit-animation-name: dropdownNav;
            animation-name: dropdownNav;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  .navicon-toggle {
    display: none;
  }
  .burger-toggler {
    display: flex;
  }
}

@-webkit-keyframes dropdownNav {
  0% {
    margin-top: -500px;
  }
  100% {
    margin-top: 0px;
  }
}

@keyframes dropdownNav {
  0% {
    margin-top: -500px;
  }
  100% {
    margin-top: 0px;
  }
}

.loginbutton__container {
  width: 190px;
  height: 44px;
  margin-left: 23px;
  margin-right: 23px;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 30px;
  border: 1px solid lightgrey;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginbutton__container:hover {
  cursor: pointer;
}

.session__time {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
}

.session__time > div {
  font-weight: bold;
}

.navigationheader__container {
  padding-left: 23px;
  margin-bottom: 43px;
}

.navigationheader__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.navigationheader__text {
  margin-left: 20px;
  font-size: 16px;
  font-weight: 600;
}

/* @font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url(./fonts/Ubuntu-Regular.woff) format("truetype");
} */

html body {
  /* height: 100%;
  width: 100%; */
  font-family: "Poppins";
  font-display: Arial;

  /* font-family: "Source Sans Pro", sans-serif; */
  /* font-family: "Source Sans Pro", sans-serif; */
}

section {
  padding-top: 60px;
  padding-bottom: 60px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* #root {
  height: 100%;
  width: 100%;
} */

.app {
  /* height: 100vh; */
  /* width: 100%;
  display: flex; */
  /* overflow: hidden; */
}

.content {
  margin-left: 237px;
  /* width: 100%; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* overflow: hidden; */
  min-height: 1000px;
  /* background-color: #f5f5f8; */
}

button:focus {
  outline: 0;
}

.btn {
  margin-top: 5px;
}

@media only screen and (max-width: 787px) {
  .content {
    /* display: none; */
    margin-left: 0px;
    margin-top: 60px;
    width: 100%;
  }
}

.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(
    135deg,
    rgb(49, 0, 62) 0%,
    rgb(195, 40, 110) 100%
  );
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}

.welcome-modal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: -60px;
  box-shadow: 0 0 1pt 600pt rgba(0, 0, 0, 0.8);
  z-index: 100;
  background-color: white;
  margin: auto auto;
  padding: 100px 100px;
  display: flex;
  top: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* font-family: Ubuntu; */
}

.modal-top {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* padding: 35px; */
  /* padding-top: 30px; */
}

.due {
  background: #ff5858;
  text-align: center;
  color: white;
  font-weight: bold;
}

.modal-top > h4 {
  font-size: 2em;
  margin-bottom: 0px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}
.modal-top > p {
  font-size: 1.2em;
  margin-bottom: 0px;
}

.modal-mid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: flex-start;

  padding-bottom: 0px;
}

.modal-mid > h3 {
  font-size: 2.5em;
  padding-top: 50px;
}

.modal-mid > ul {
  font-size: 1.5em;
}

.challenge-warning > h4 {
  margin-left: -20px;
  margin-right: -20px;
  background-color: #e56f6f;
  padding: 20px;
  color: white;
  font-weight: bold;
}

.modal-bottom {
  width: 100%;
  height: 10%;
  text-align: left;
  padding-left: 30px;
  margin-top: -30px;
}

.modal-options {
  width: 100%;
  height: 80%;
}

.month-label {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.month-modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 50px 30px 0 30px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.insurance-text {
  margin: 40px;
  margin-bottom: 0px;
}

.job-text {
  margin: 40px;
  margin-bottom: 0px;
}

.radio {
  width: 180px;
  height: 250px;
  box-shadow: 3px 4px 20px 2px rgb(223, 220, 220);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

.radio-parents {
  width: 180px;
  height: 250px;
  box-shadow: 3px 4px 20px 2px rgb(223, 220, 220);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

.radio-parents > label {
  background: #ff5858;
  color: white;
  font-weight: bold;
  font-size: 1.5em;
}

.radio:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  z-index: 2;
  cursor: pointer;
}

.option {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.option-text {
  margin: 0.2em;
}

.job-image {
  width: auto;
  height: 75px;
}

.job-options {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  max-width: 500px;
  align-items: center;
  overflow-y: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 20px;

  /* spacing as needed */

  /* let it scroll */
  overflow-y: auto;
}

.budget-alert {
  color: red;
  font-weight: bold;
  padding: 20px;
  width: 300px;
  bottom: 0px;
  left: 45%;
  position: fixed;
  border-radius: 10px;
  box-shadow: 0 0 1pt 1500pt rgba(0, 0, 0, 0.8);
  z-index: 1;
  background-color: white;
  margin: 100px 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -100px;
  -webkit-animation-name: slide-left-alert;
          animation-name: slide-left-alert;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
  /* font-family: Ubuntu; */
  z-index: 10;
  /* font-family: Ubuntu;
  z-index: 10;
  font-weight: bold;
  font-size: 1.2em; */
}

@-webkit-keyframes slide-left-alert {
  0% {
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

@keyframes slide-left-alert {
  0% {
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

.alert-close {
  align-self: flex-end;
  width: 40px;
  height: 40px;
  border: none;
  margin: 0;
  background-color: red;
  color: white;
  font-weight: bold;
}

.add-info-insurance {
  margin: 5px;
  font-size: 12px;
  text-align: center;
}

.insurance-options {
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 80%; */

  /* spacing as needed */
  padding: 20px 20px 20px 20px;
  margin-top: 10px;
  margin-bottom: 30px;
}

label {
  padding: 10px;
  text-align: center;
  font-size: 15px;
}

label:hover {
  cursor: pointer;
  /* background-color:#daeeff;
  border-radius: 5px; */
}

.start-form {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.winning-bar,
.losing-bar {
  width: 100%;
  height: 60px;
  color: white;
  text-align: center;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 10px 0 10px 0; */
  -webkit-animation-name: slide-bottom;
          animation-name: slide-bottom;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.winning-bar {
  background-color: #3e3e3e;
  padding-top: 10px;
}

.winning-bar > h5 {
  color: white;
  margin: 0px;
}

.losing-bar > h5 {
  background-color: red;
  color: white;
  font-weight: bold;
  padding: 20px;
  width: 100%;
}

@-webkit-keyframes slide-bottom {
  0% {
    margin-bottom: -50px;
  }
  100% {
    margin-bottom: 0px;
  }
}

@keyframes slide-bottom {
  0% {
    margin-bottom: -50px;
  }
  100% {
    margin-bottom: 0px;
  }
}

.recommendation {
  padding: 10px;
  color: black;
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 0px;
  background: antiquewhite;
  -webkit-animation-name: slideTop;
          animation-name: slideTop;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  opacity: 0;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes slideTop {
  0% {
    margin-top: -30px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    margin-bottom: 0px;
    opacity: 1;
    z-index: 1;
  }
}

@keyframes slideTop {
  0% {
    margin-top: -30px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    margin-bottom: 0px;
    opacity: 1;
    z-index: 1;
  }
}

.loading {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f7fc;
  padding-right: 100px;
}

.load-wrapp {
  padding: 20px 20px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 100px;
}
.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #4b9cdb;
  margin: 2px;
}
/* =Animate the stuff
------------------------ */
.load-1 .line:nth-last-child(1) {
  -webkit-animation: loadingA 1.5s 1s infinite;
          animation: loadingA 1.5s 1s infinite;
}
.load-1 .line:nth-last-child(2) {
  -webkit-animation: loadingA 1.5s 0.5s infinite;
          animation: loadingA 1.5s 0.5s infinite;
}
.load-1 .line:nth-last-child(3) {
  -webkit-animation: loadingA 1.5s 0s infinite;
          animation: loadingA 1.5s 0s infinite;
}
.l-1 {
  -webkit-animation-delay: 0.48s;
          animation-delay: 0.48s;
}

@-webkit-keyframes loadingA {
  0 {
    height: 15px;
  }
  50% {
    height: 35px;
  }
  100% {
    height: 15px;
  }
}

@keyframes loadingA {
  0 {
    height: 15px;
  }
  50% {
    height: 35px;
  }
  100% {
    height: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .modal {
    width: 80%;
    padding: 20px;
  }

  .modal-top {
    /* padding: 15px; */
    margin: 15px;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.admin-row{
  background-color: white;
  font-size: 0.7em;
  font-size: 0.7em;
}
.admin-row:last-child{
  background-color: white !important;
}

tr:last-child{
  background-color: white !important;
}
.admin-container{
  margin: 20px;
  padding:15px;
}
.login-modal {
  position: fixed;
  display: flex;
  z-index: 20;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100vw;
  height: 100vh;
  margin-left: -80px;
}

.login-modal-container {
  /* width: 70%; */
  max-width: 900px;
  min-height: 500px;
  height: 60%;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: row;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-animation-name: expand-front;
          animation-name: expand-front;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes expand-front {
  0% {
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes expand-front {
  0% {
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.login-modal-info {
  background-color: #3f3d55;
  flex: 1 1;
  padding: 40px;
}

.login-modal-info-item {
  margin-bottom: 10px;
}

.login-modal-info > h4 {
  color: white;
  margin-bottom: 30px;
}

.login-modal-login-button {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}

.sign-in-modal-google {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 12px 24px;
  background: white;
  border-radius: 15px;
  margin-top: 30px;
  max-width: 300px;
}

.sign-in-modal-google:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: all 200ms ease 0s;
}

.login-modal-info-item > p {
  color: #999999;
}
.login-modal-info-item > h5 {
  color: #c6c3c3;
}

@media only screen and (max-width: 1066px) {
  .login-modal {
    margin: 0px;
    width: 100%;
    position: relative;
  }
}

@media only screen and (max-width: 787px) {
  .login-modal {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .login-modal-info {
    display: none;
  }
}

.teacherview-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.single-classroom {
  padding: 20px;
  margin: 20px;
  max-width: 400px;
  background-color: white;
  margin-left: 0px;
  -webkit-animation-name: slide-left;
          animation-name: slide-left;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 5px;
  align-items: center;
}

.single-classroom > h4 {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-weight: bold;
}

@-webkit-keyframes slide-left {
  0% {
    margin-left: -30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@keyframes slide-left {
  0% {
    margin-left: -30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

.classrooms-container {
  padding: 30px;
}

.classrooms-container > h3 {
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-bottom: 20px;
  font-weight: bold;
}

.classrooms-container > p {
  margin-bottom: 0px;
  font-weight: bold;
}

.classid-name {
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
  background-color: #3e3e3e;
  color: white;
  margin-bottom: 10px;
  max-width: 300px;
}

.classroom-container {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.classroom-stats {
  margin: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.classroom-stats > h5 {
  margin-bottom: 0;
  opacity: 0.6;
  font-size: 16px;
  /* margin-top: 40px; */
}

.classroom-stats > h3 {
  font-weight: bold;
  /* margin-bottom: 20px; */
}

.classroom-stats > p {
  opacity: 0.6;
}

.view-class-container {
  padding: 30px;
  /* height: 80vh; */
  overflow: scroll;
  padding-left: 10px;
}

.togglestudentviews__container {
  margin-bottom: 10px;
}

.exportdata__button {
  /* position: absolute; */
  /* right: 0px; */
  /* margin-right: 100px; */
  /* margin-left: 50px; */
  /* float: right; */
  margin-left: 20px;
}

.student-row {
  padding: 10px;
  margin: 5px;
  background-color: white;
  width: 400px;
}

.student-row-name {
  margin-bottom: 4px;
  font-weight: bold;
}

.student-data {
  padding: 3px 5px;
}

.student-row-lesson-data {
  /* width: 500px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.not-started-container {
  padding: 10px;
  background-color: white;
  height: 18vh;
  font-weight: bold;
  /* font-size: 1.5em; */
  margin-top: 20px;
}

.not-started-students {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.not-started {
  background-color: white;
  /* color: white; */
  border: 0.5px solid lightgrey;
  padding: 2px 4px;
  max-width: 100px;
  margin: 3px;
  font-size: 12px;
}

.classroom-progress-cols {
  display: flex;
  flex-direction: column;
  /* width: 90vw; */
  justify-content: flex-start;
}

.progress-col {
  margin: 20px;
  margin-left: 0px;
  width: 250px;
}

.progress-col > h5 {
  /* height: 50px; */

  padding-bottom: 0px;
  margin-bottom: 10px;
  font-style: 18px;
}

.progress-game-cols {
  display: flex;
  flex-direction: row;
}

.progress-lesson-col > h5 {
  margin: 10px;
  margin-left: 0px;
  max-width: 100px;
  font-weight: bold;
  font-size: 1em;
}

.progress-col {
  width: 100;
}

.classdata__refresh {
  
}

.dashboard-levels {
  display: flex;
}
.progress-col > h5 {
  font-weight: bold;
}

.student-level {
  display: flex;
  justify-content: space-between;
  background: white;
  padding-right: 5px;
  padding: 3px 5px;
  font-size: 13px;
  border-radius: 5px;
  border: 0.5px solid lightgrey;
  margin-bottom: 5px;
  width: 200px;
}

.dashboard-level-container {
  margin: 10px;
  margin-left: 0;
  padding: 20px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.budgeting-game-done {
  background-color: green;
  color: white;
  padding: 2px 3px;
}

.dashboard-level-container > h2 {
  font-size: 24px;
  font-weight: bold;
}

.last__login {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}

.studenttable__container {
}
.editname__button {
  font-size: 10px;
  /* background-color: blue; */
  border: 1px solid lightgrey;
}

.userfirstlast__container {
  display: flex;
  justify-content: space-between;
}

.userfirstlast__container > div {
  font-size: 14px;
  min-width: 200px;
}

.studenttable > tr {
}

.offline__online__student {
  font-size: 14px;
}

.teacheremails__container {
  padding: 5px;
  /* border: 1px solid lightgrey; */
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 10px;
  font-weight: bold;
}

.lessonprogresslesson__container {
  padding: 20px 10px;
  font-weight: bold;
  font-size: 16px;

  margin-bottom: 10px;
  border: 2px solid lightgrey;
  overflow: scroll;
}

.lessonprogresslesson__container > h3 {
  font-weight: bold;
}

.lessonSections__container {
  display: flex;
  flex-direction: column;
  /* border: 1px solid lightgrey; */
  /* margin-right: 40px; */
  min-width: 150px;
}

.usersectionsdata__container {
  display: flex;
  flex-direction: column;
}

.sectioncolumn__container {
  display: flex;
  flex-direction: row;
}

.userprogress__box {
  display: flex;
  font-weight: normal;
  padding: 1px 2px;
  flex-direction: row;

  font-size: 13px;
  text-align: center;
  justify-content: center;
}

.lessonSection__name {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  font-size: 12px;

  margin-bottom: 20px;
  text-align: center;
}

.lessonsection__completecount {
  font-size: 12px;
  opacity: 0.6;
}

.usersection__complete {
  background-color: lightgreen;
}

.lessonusers__container {
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  /* background-color: blue; */
}

.username__infocontainer {
  /* background-color: blue; */
  font-size: 14px;
  font-weight: normal;
  height: 21px;
  width: 190px;
}

.usersinlesson__container {
}

.finance-sheets-container {
  padding: 40px;
}

.finance-sheets {
  /* padding-left: 10px; */
  margin-top: 20px;
}

.finance__sheet {
  margin: 5px;
  margin-left: 0;
  padding-left: 0;
  padding: 10px;
  background-color: white;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 400px;
}

