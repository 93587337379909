.classrooms-container {
  padding: 30px;
}

.classrooms-container > h3 {
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-bottom: 20px;
  font-weight: bold;
}

.classrooms-container > p {
  margin-bottom: 0px;
  font-weight: bold;
}

.classid-name {
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
  background-color: #3e3e3e;
  color: white;
  margin-bottom: 10px;
  max-width: 300px;
}
