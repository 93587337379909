.choose {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: left;
  /* background-color: #f5f5f8; */
}

.sim-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin: 40px 0px 0px 60px; */
  padding: 30px;
  padding-left: 50px;
  min-height: 100vh;
  /* background-color: #f5f5f8; */
}

.inactive-bar-pro {
  height: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.inactive-bar-pro > h5 {
  border-radius: 15px;
  padding: 5px;
  background: red;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.inactive-bar-pro > h5:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.game-card {
  display: flex;
  flex-direction: row;
  animation-name: slideLeft;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes slideLeft {
  0% {
    margin-left: -50px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

.game-description {
  width: 50%;
  font-size: 18px;
}

.simulations {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  flex-direction: column;
}

.active-card,
.inactive-card {
  height: 280px;
  width: 410px;
  background-color: yellow;
  margin: 0px 70px 45px 0px;
  box-shadow: 4px 3px 25px 0px rgb(223, 220, 220);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.active-card:hover {
  text-decoration: none;
  cursor: pointer;
  box-shadow: 5px 7px 25px 0px rgb(223, 220, 220);
  display: flex;
  transform: scale(1.05);
  transition: all 0.2s;
  margin-left: 20px;
}

.active-card:hover .game-description {
  display: inline;
}

.card-graphic {
  height: 84%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-img {
  max-width: 300px;
  max-height: 180px;
  width: auto;
  height: auto;
}

.active-bar,
.inactive-bar {
  height: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.active-bar {
  background-color: #213042;
}

.inactive-bar {
  background-color: #a5caf4;
}

.sim-header {
  width: 80%;
  margin-top: 30px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: left;
  color: #525252;
  /* margin-bottom: 30px; */
}

.sim-title {
  font-size: 2.4rem;
  margin-bottom: 0.1rem;
  font-weight: bold;
}

.sim-subtitle {
  color: #a5a2a2;
  font-size: 1.2rem;
  margin-bottom: 0px;
}

.card-title {
  color: #525252;
  text-decoration: none;
  margin: 0;
}

.card-message {
  color: white;
  margin: 0;
  font-size: 1.4rem;
}

.card-message > h5 {
  border-radius: 15px;
  padding: 5px;
  background: red;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 960px) {
  .game-card {
    flex-direction: column;
    padding-bottom: 20px;
  }

  .choose {
    display: inline;
  }

  .game-description {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    text-align: left;
    width: 80%;
  }

  .sim-menu {
    margin: 30px;
    margin-top: 0px;
    width: 100%;
    margin-left: 0px;
    padding: 30px;
    /* margin-left: 10px; */
    /* background-color: #f5f5f8; */
  }

  .sim-header {
    height: auto;
  }

  .ant-steps {
    display: flex;
    margin-left: -20px;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  .ant-steps-vertical {
    margin-left: -20px;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
  }

  .active-card,
  .inactive-card {
    width: auto;
    /* padding: 5px; */
    background-color: white;
    height: auto;
    margin-right: 0px;
  }

  .active-bar,
  .inactive-bar {
    height: 40px;
  }

  .card-title {
  }
}
