.teacherview-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.single-classroom {
  padding: 20px;
  margin: 20px;
  max-width: 400px;
  background-color: white;
  margin-left: 0px;
  animation-name: slide-left;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 5px;
  align-items: center;
}

.single-classroom > h4 {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-weight: bold;
}

@keyframes slide-left {
  0% {
    margin-left: -30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}
