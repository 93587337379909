.login-modal {
  position: fixed;
  display: flex;
  z-index: 20;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100vw;
  height: 100vh;
  margin-left: -80px;
}

.login-modal-container {
  /* width: 70%; */
  max-width: 900px;
  min-height: 500px;
  height: 60%;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: row;
  transform: scale(0.8);
  animation-name: expand-front;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes expand-front {
  0% {
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.login-modal-info {
  background-color: #3f3d55;
  flex: 1;
  padding: 40px;
}

.login-modal-info-item {
  margin-bottom: 10px;
}

.login-modal-info > h4 {
  color: white;
  margin-bottom: 30px;
}

.login-modal-login-button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}

.sign-in-modal-google {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 12px 24px;
  background: white;
  border-radius: 15px;
  margin-top: 30px;
  max-width: 300px;
}

.sign-in-modal-google:hover {
  transform: scale(1.05);
  transition: all 200ms ease 0s;
}

.login-modal-info-item > p {
  color: #999999;
}
.login-modal-info-item > h5 {
  color: #c6c3c3;
}

@media only screen and (max-width: 1066px) {
  .login-modal {
    margin: 0px;
    width: 100%;
    position: relative;
  }
}

@media only screen and (max-width: 787px) {
  .login-modal {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .login-modal-info {
    display: none;
  }
}
