.options-box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.quiz-title {
  font-weight: bold;
}

.quiz-question {
  font-weight: bold;
  font-size: 1.3em;
}

.quiz-answer {
  flex: 1;
  border: none;
  padding: 20px;
  margin: 3px;
  font-weight: bold;
}

.quiz-answer:hover {
  background-color: #3e3e3e;
  color: white;
}

.quiz-questions-container {
  max-width: 40vw;
  animation-name: questions-appear;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  min-height: 200px;
}

.results-need-snowball {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 50vw;
  min-height: 100px;
}

.results-button {
  margin-top: 30px;
  padding: 10px 40px 10px 40px;
  border-radius: 6px;
  border: none;
  background-color: #3e3e3e;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}

.results-need-snowball > h4 {
  text-align: center;
}
.results-need-snowball > p {
  margin-top: 10px;
  text-align: center;
  font-size: 1.2em;
}

.failed-span {
  background-color: #ed5252;
  color: white;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.success-span {
  background-color: #50ea50;
  color: white;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

@keyframes questions-appear {
  0% {
    /* margin-bottom: -30px; */
    opacity: 0;
  }
  100% {
    /* margin-bottom: -30px; */
    opacity: 1;
  }
}
