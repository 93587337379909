/* Full landing Container */
.landing-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
}

.discount {
  background-color: #ed5252;
  color: white;
  text-align: center;
  padding: 20px;
  /* align-self: center; */
}

.discount > button {
  border: none;
  margin: 5px;
  color: black;
}

.newsletter__container{
  background-color: black;
  margin: 50px;
}

/* Landing First Thing you see */

.intro-landing {
  display: flex;
  flex: 1;
  background-color: white;
  padding: 150px;
  padding-bottom: 100px;
  flex-direction: row;
  justify-content: space-between;
}

.play-now {
  width: 250px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #3f3d55;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 20px;
  text-align: center;
  animation-name: fadeInFromLeft;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.brands {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 80vw;
  align-self: center;
}

.brands:first-child {
  width: 400px;
}

.brands-title {
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
}

.brand-image {
  /* width: auto; */
  margin-bottom: 30px;
  height: auto;

  max-height: 90px;
  /* max-width: 300px; */
  height: auto;
}

.intro-landing-text-container {
  display: flex;
  justify-content: center;
  color: #3e3e3e;
  font-weight: bold;
}

.intro-landing-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-weight: bold;
  max-width: 400px;
}

.intro-landing-text > h1 {
  font-size: 3em;
  opacity: 0;
  max-width: 400px;
  color: #3e3e3e;
  font-weight: bold;
  padding-bottom: 0px;
  margin-bottom: 0px;
  animation-name: fadeInFromLeft;
  animation-delay: 0.2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.intro-landing-text > p {
  font-size: 1.8em;
  padding-top: 20px;
  opacity: 0;
  animation-name: fadeInFromLeft;
  animation-delay: 0.2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.landing-action-button {
  background-color: #3f3d56;
  color: white;
  font-size: 1.4em;
  opacity: 0;
  animation-name: fadeInFromLeft;
  animation-delay: 0.2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.row-container-1-image {
  animation-name: fadeInFromRight;
  opacity: 0;
  animation-delay: 0.2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

/* Landing Second Container */

.games-showoff-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 150px;
  padding-bottom: 100px;
}

.showoff-image {
  max-width: 600px;
  animation-name: fadeInFromRight;
  opacity: 0;
  animation-delay: 0.2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

/* Third Container */
.showoff-sheets-container {
  padding: 120px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.showoff-sheets-text {
  margin-bottom: 50px;
  font-size: 1em;
}

.showoff-text {
  /* min-width: 400px; */
}

.showoff-sheets-text > h1 {
  font-size: 3em;
  opacity: 0;
  color: #3e3e3e;
  font-weight: bold;
  text-align: left;
  padding-bottom: 0px;
  margin-bottom: 0px;
  animation-name: fadeInFromLeft;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.showoff-sheets-text > p {
  font-size: 1.8em;
  padding-top: 20px;
  text-align: center;
  opacity: 0;
  animation-name: fadeInFromLeft;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

/* Fourth Container */
.features-container {
  display: flex;
  flex-direction: row;
  padding: 120px;
  padding-top: 80px;
  margin-bottom: 50px;
  justify-content: space-around;
}

.features-text-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  justify-content: center;
}

.features-text-container > h1 {
  /* font-weight: bold; */
  font-size: 32px;
  margin-bottom: 15px;
}

.features-list > p {
  font-size: 18px;
  margin-bottom: 10px;
  /* padding-left: 20px; */
  text-align: right;
  display: flex;
}

.features-list {
  margin-bottom: 20px;
}

.landing-button-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 100px;
  opacity: 0;
  animation-name: fadeInFromLeft;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.landing-button-bottom > button {
  width: 50%;
  align-self: center;
  margin-bottom: 100px;
  background-color: #3f3d56;
  color: white;
  font-size: 1.4em;
  box-shadow: none;
}

.landing-button-bottom > h1 {
  font-size: 4em;
}

.google-login {
  opacity: 0;
  animation-name: fadeInFromLeft;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.start-now-button {
  background-color: rgb(28, 29, 31);
  color: white;
}

.row-container-2-image {
  width: 70vw;
}

@keyframes fadeInFromLeft {
  0% {
    margin-left: -30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@keyframes fadeInFromRight {
  0% {
    margin-right: -30px;
    opacity: 0;
  }
  100% {
    margin-right: 0px;
    opacity: 1;
  }
}

@media only screen and (max-width: 1350px) {
  /* Landing first thing you see mobile */

  .landing-container > section:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  .intro-landing {
    padding: 20px;
    flex-direction: column;
    /* margin-top: 120px; */
    /* margin-bottom: 120px; */
  }
  .intro-landing-image-container {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 300px;
    align-self: center;
  }

  .intro-landing-text-container {
    /* justify-content: flex-start;
    padding: 40px; */
    margin-top: 60px;
  }

  .showoff-sheets-text > h1 {
    font-size: 3em;
  }

  .showoff-sheets-text > p {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .features-text-container > h1 {
    font-size: 2em;
  }

  .features-text-container {
    text-align: center;
  }

  .features-list {
    margin-top: 15px;
    max-width: 600px;
    height: auto;
  }

  .row-container-features-image {
    display: none;
  }

  .features-list > p {
    font-size: 1.5em;
    opacity: 0.7;
  }

  .intro-landing-text > h1 {
    font-size: 3em;
  }

  .games-showoff-container {
    flex-direction: column;
    align-items: center;
  }
  .row-container-1-image {
    padding: 20px;
    width: 600px;
    max-width: 100vw;
    /* max-width: 30vw; */
  }

  /* Second container mobile */

  .landing-action-button {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 120px;
  }

  .games-showoff-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    padding: 20px;
    margin-bottom: 120px;
  }

  .showoff-image {
    /* margin-top: 0px; */
    width: 80vw;
    /* max-width: 100%; */
    /* max-width: 100vw; */
    /* height: auto; */
  }
  .showoff-text {
    margin-top: 50px;
    align-self: center;
    padding-bottom: 20px;
    padding: 20px;
  }

  /* Third Container mobile */
  .showoff-sheets-container {
    padding: 20px;
    /* padding-top: 100px; */
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
  }

  .showoff-sheets-text {
    align-items: flex-start;
    text-align: center;
  }

  .showoff-sheets-text > p {
    text-align: left;
  }

  .showoff-sheets-container > img {
    margin-right: 20px;
  }

  /* Fourth Container */
  .features-container {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  .builtwith-images {
    display: flex;
  }

  .builtwith-images > img {
    min-width: 150px;
    background-color: blue;
  }

  .features-text-container {
    align-items: center;
    align-items: flex-start;
  }
  .features-text-container > h1 {
    text-align: center;
  }

  .features-list-container {
    justify-content: center;
    align-items: center;
  }

  .features-list {
    align-items: flex-start;
    margin-bottom: 100px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }

  .landing-button-bottom {
    text-align: center;
  }

  .row-container-features-image {
    padding: 20px;
    height: auto;
    width: auto;
  }

  .start-now-button {
    background-color: rgb(28, 29, 31);
    color: white;
    width: 100%;
    margin-top: 0px;
    font-size: 1.4em;
    margin-bottom: 90px;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 813px) {
}

@media only screen and (max-width: 320px) {
  .showoff-sheets-container {
    width: 100%;
    font-size: 0.8em;
  }

  .ant-steps-item-container {
    margin-left: -75px;
  }

  .card-graphic {
    /* margin-left: -20px; */
    z-index: 10;
  }

  .features-text-container > h1 {
    font-size: 2em;
  }
}
